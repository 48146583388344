import { isArray, isString, isNumber } from './is'
import type { MenuMapperModel, RouterOrLink } from '@/typings/global'
import Config, { App, ApiProxy } from '@/config'
import { cloneDeep, map, find } from 'lodash-es'
import { ref, Ref } from 'vue'
import { nFileBase64ById, addNewsNoticeVisitCount } from '@/api'
import router from '@/router'
import { AppsRo, PolicyItem } from '@/api/types/java'
import PubSub from 'pubsub-js'
import { loginDialog } from '@/global/pubsub'
import { LDOpenParam } from '@/typings/global'
import { ElNotification } from 'element-plus'
import store from '@/store'
import { ResponseMode } from '@/typings/params'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { useSessionStorage } from '@vueuse/core'

dayjs.extend(duration)
window.dayjs = dayjs

/**
 * about colors
 */
export * from './colors'

/**
 * about load
 */
export * from './load'

/**
 * about is
 */
export * from './is'

/**
 * Generate random string by Math.random and time
 */
export const generateId = (): string => `${ Math.floor(Math.random() * 10) }${ new Date().getTime() }`

/**
 * @desc 转换平级数据为树形数据
 */
export function transformToTree (sNodes: Array<any>, setting?: MenuMapperModel) {
  const set = {
    children: 'children',
    idKey: 'id',
    pIdKey: 'parentId',
    label: 'name'
  } as MenuMapperModel

  setting && Object.assign(set, setting)

  let i
  let l
  const key = set.idKey
  const parentKey = set.pIdKey || ''
  const childKey = set.children || ''
  if (!key || key === '' || !sNodes) return []
  if (Array.isArray(sNodes)) {
    sNodes.sort((a, b) => (a.orderNum - b.orderNum))
    const r = []
    const tmpMap = []
    for (i = 0, l = sNodes.length; i < l; i++) {
      // sNodes[i][childKey] = [{}]
      tmpMap[sNodes[i][key]] = sNodes[i]
    }
    for (i = 0, l = sNodes.length; i < l; i++) {
      if (
          tmpMap[sNodes[i][parentKey]] &&
          sNodes[i][key] !== sNodes[i][parentKey]
      ) {
        const children = tmpMap[sNodes[i][parentKey]][childKey] || []
        children.push(sNodes[i])
        children.sort((a: any, b: any) => (a.orderNum - b.orderNum))
        tmpMap[sNodes[i][parentKey]][childKey] = children
      } else {
        r.push(sNodes[i])
      }
    }

    return r
  } else {
    return [sNodes]
  }
}

/**
 * 首字母转大写
 */
export function initialToUpperCase (str: string) {
  if (!isString(str)) return str
  const strs = str.split('')
  strs[0] = strs[0].toUpperCase()
  return strs.join('')
}

/*
* @desc 跳转
* @param {object} item 跳转对象
* @param {object} params 跳转添加的参数
* */
export function open (item: any, params: any = {}) {
  const iPath = item.path ? String(item.path || item.routerPath) : ''
  const iLink = item.link ? String(item.link) : ''
  switch (true) {
    case iPath.includes('/micro/'):
      router.push(replaceUrlParams(iPath, params, true))
      break
    case iPath && iPath !== '#':
      router.push(replaceUrlParams(iPath, params, true))
      break
    case iLink && iLink !== '#':
      const link = replaceUrlParams(iLink, params, false)
      if (isString(link)) {
        outLinkOpen(link)
      } else {
        router.push(link)
      }
      break
    default:
      if (!iPath || iPath === '#') return
      router.push(replaceUrlParams(iPath, params, true))
      break
  }
}

export function routerOrLink (data: RouterOrLink) {
  switch (true) {
    case !!data.router:
      router.push(data.router)
      break
    case !!data.link:
      window.open(data.link)
      break
    default:
      break
  }
}

// 替换地址中{}包裹的参数,路由则返回路由对象
export function replaceUrlParams (url: string, params: any, isRouter = false) {
  if (!url) return url
  const reg = /\{([^}]+)\}/g
  const result = url.match(reg)
  if (!result) return url
  result.forEach((item: string) => {
    const key = item.replace(/[{}]/g, '')
    url = url.replace(item, params[key])
  })
  if (!isRouter) {
    return url
  } else {
    return {
      path: url,
      query: getUrlParams(url)
    }
  }
}

// 获取地址栏url中的参数
export function getUrlParams (url: string) {
  const params: any = {}
  const arr = url.split('?')
  if (arr.length > 1) {
    const str = arr[1]
    const strs = str.split('&')
    for (let i = 0, l = strs.length; i < l; i++) {
      const param = strs[i].split('=')
      params[param[0]] = param[1]
    }
  }
  return params
}

/**
 * 跳转登录
 */
export async function ssoLogin (gotoUrl: string = location.href) {
  const { DEV, VITE_DOMAIN } = import.meta.env
  const domain = DEV ? VITE_DOMAIN : location.origin
  const href = `${ domain }/fib-screen/index.html#/login?redirect=${ gotoUrl }`
  window.location.href = href
}

/**
 * 获取url信息
 * @param key
 * @returns
 */
export function getQuery (key: string | string[]) {
  const url = location.href.split('?')
  const p = url?.[1]?.split('&')
  if (!p || p.length < 1) return
  const result = {} as any
  let str = ''
  const keyIsArray = isArray(key)
  p.every(item => {
    const itemKey = item.split('=')[0]
    let itemValue = item.split('=')[1] || ''
    if (itemValue.indexOf('#/') >= 0) {
      itemValue = itemValue.replace('#/', '')
    }
    if (!keyIsArray && key && itemKey === key) {
      str = itemValue
      return false
    }

    if (!key || (keyIsArray && key.includes(itemKey))) {
      result[itemKey] = itemValue
    }
    return true
  })
  return keyIsArray || !key ? result : str
}

/**
 * 时间转换
 */
export function parseTime (time: string | number | Date, cFormat?: string) {
  if (!time) {
    return ''
  }
  if (arguments.length === 0) {
    return null
  }

  if ((time + '').length === 10) {
    time = +time * 1000
  }

  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    date = new Date(parseInt(String(time)))
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  } as any
  const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result: any, key: string | number) => {
    let value = formatObj[key]
    if (key === 'a') return ['一', '二', '三', '四', '五', '六', '日'][value - 1]
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return timeStr
}

/**
 * 时间范围
 */
export function getTimeRange (start: number, end: number) {
  const year1 = new Date(start).getFullYear()
  const year2 = new Date(end).getFullYear()
  if (year1 === year2) {
    return `${ parseTime(start, '{y}-{m}-{d} {h}:{i}') }~${ parseTime(end, '{m}-{d} {h}:{i}') }`
  } else {
    return `${ parseTime(start, '{y}-{m}-{d} {h}:{i}') }~${ parseTime(end, '{y}-{m}-{d} {h}:{i}') }`
  }
}

/**
 * 判断数据类型
 */
export function dataType (data: unknown) {
  let type = 'String'

  if (Object.prototype.toString.call(data) === '[object Object]') {
    type = 'Object'
  } else if (Object.prototype.toString.call(data) === '[object Array]') {
    type = 'Array'
  } else if (typeof data === 'string') {
    type = 'String'
  } else if (typeof data === 'number') {
    type = 'Number'
  }

  return type
}

/**
 * 通过结构获取数据
 * @param {*} data
 * @param {*} structure
 */
export function deepData (data: any, structure: string, type = 'clone') {
  if (!structure || !data) return data
  const _arr = structure.split('.')
  let convertData = data
  let parentData = data
  let lastKey = ''
  while (_arr.length > 0 && convertData && Object.keys(convertData).length > 0) {
    let key: any = _arr.shift() + ''
    // 判断为数组
    if (key.indexOf('[') >= 0 && key.indexOf(']') >= 0) {
      key = key.substring(1, key.length - 1) * 1
    }
    lastKey = key
    parentData = convertData
    convertData = convertData[key]
  }
  // 获取的对象改变后 是否改变原来对象
  switch (type) {
    case 'clone':
      if (['Array', 'Object'].includes(dataType(convertData))) {
        convertData = cloneDeep(convertData)
      }
      break
    case 'headUp':
      convertData = { convertData, parentData, key: lastKey }
      break
    default:
      break
  }

  return convertData
}

/**
 * 转化value
 */
export function convertValue (argValue: string | number, argType: string | string[], argOption: any | any[]): any {
  if (!argType) return argValue
  let types: any = argType
  if (!isArray(argType)) {
    types = [argType]
  }

  let options: any = argOption
  if (!isArray(argOption)) {
    options = [argOption]
  }

  let value: any = argValue || ''
  let unit = ''
  let defaultValue = value

  let raise = ''
  types.map((item: string, index: number) => {
    if (
        ![
          'dict',
          'file',
          'image',
          'slot',
          'unit',
          'structure',
          'pubsub',
          'router',
          'number',
          'link',
          'html',
          'substring',
          'diy'
        ].includes(item)
    ) {
      // value = Number(value || 0)
    }
    const option = options[index]

    const fixed = option.fixed !== undefined ? option.fixed : 2
    switch (item) {
      case 'defaultValue':
        defaultValue = isString(option.value) && ['[', '{'].includes(option.value.substring(0, 1)) ? JSON.parse(option.value) : option.value
        value = (value === undefined || value === '') ? option.value : value
        break
      case 'substring':
        value = String(value).substring(option.start || 0, option.end || String(value).length)
        break
      case 'raise':
        raise = value >= 0 ? '+' : '-'
        value = raise + value
        break
      case 'time':
        value = parseTime(value, option.value)
        break
      case 'number':
        if (isNumber(value)) {
          switch (option.value) {
            case '+':
              value = (value + option.number).toFixed(fixed)
              break
            case '-':
              value = (value - option.number).toFixed(fixed)
              break
            case '*':
              value = (value * option.number).toFixed(fixed)
              break
            case '/':
              value = (value / option.number).toFixed(fixed)
              break
          }
          value = value * 1
        } else {
          value = ''
        }
        break
      case 'dict':
        break
      case 'unit':
        unit = option.value
        value = value + option.value
        break
      default:
        break
    }
  })
  return { value, unit, defaultValue }
}

/**
 *
 * @param {*} url 链接
 * @param {*} type 类型: 'auth'： 需要登录并传递参数
 */
export function outLinkOpen (url: string) {
  window.open(url)
}

/**
 *
 * @param menus
 */
// export function setPicture<T> (data: T | Array<T>): Array<T> {
//   let _useData: Array<T>
//   if (!Array.isArray(data)) _useData = [data]
//   else _useData = data

//   const useData: Ref<T[]> = ref([])

//   _useData.map((item: any) => {
//     if (!item) return
//     const picture: string | undefined = item.picture
//     if (picture && picture.substring(0, 1) === '[') {
//       const itemData: any = ref(cloneDeep(item))
//       item.picture = undefined
//       nFileBase64ById(JSON.parse(picture)[0].url).then((base64: any) => {
//         itemData.value.picture = base64
//       })
//       useData.value.push(itemData.value)
//     }
//   })

//   return useData.value
// }
export function setPicture<T> (data: T | Array<T>, keys = ['picture']): Array<T> {
  let _useData: Array<T>
  if (!Array.isArray(data)) _useData = [data]
  else _useData = data
  const useData: Ref<T[]> = ref([])
  _useData.map((item: any) => {
    if (!item) return
    keys.map(key => {
      const picture: string | undefined = item[key]
      const itemData: any = ref(item)
      if (picture && picture.substring(0, 1) === '[') {
        item[key] = undefined
        nFileBase64ById(JSON.parse(picture)[0].url).then((base64: any) => {
          itemData.value[key] = base64
        })
      }
      useData.value.push(itemData.value)
    })
  })
  return useData.value
}

// 深度合并
export function deepMerge (arrive: any, source: any) {
  if (Object.prototype.toString.call(arrive) === '[object Object]' && Object.prototype.toString.call(source) === '[object Object]') {
    for (const prop2 in source) {
      if (!arrive[prop2]) {
        arrive[prop2] = source[prop2]
      } else {
        arrive[prop2] = deepMerge(arrive[prop2], source[prop2])
      }
    }
  } else if (Object.prototype.toString.call(arrive) === '[object Array]' && Object.prototype.toString.call(source) === '[object Array]') {
    // 两个都是数组，进行合并
    source.map((o: any, i: number) => {
      arrive[i] = deepMerge(arrive[i], source[i])
    })
  } else {
    arrive = source
  }
  return arrive
}

/**
 * 生成uuid
 */
export function uuid (size = 18, prefix?: string) {
  const s: any[] = []
  const hexDigits = '0123456789abcdef'
  for (let i = 0; i < size; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }
  s[7] = '4' // bits 12-15 of the time_hi_and_version field to 0010
  s[9] = hexDigits.substr((s[9] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[11] = '-'

  const uuid = s.join('')
  return (prefix || '') + uuid
}

export function calculateCenter (lnglatarr: any) {
  const total = lnglatarr.length
  let X = 0
  let Y = 0
  let Z = 0
  lnglatarr.map((lnglat: number[]) => {
    const lng = lnglat[0] * Math.PI / 180
    const lat = lnglat[1] * Math.PI / 180
    const x = Math.cos(lat) * Math.cos(lng)
    const y = Math.cos(lat) * Math.sin(lng)
    const z = Math.sin(lat)
    X += x
    Y += y
    Z += z
  })
  X = X / total
  Y = Y / total
  Z = Z / total
  const Lng = Math.atan2(Y, X)
  const Hyp = Math.sqrt(X * X + Y * Y)
  const Lat = Math.atan2(Z, Hyp)
  return new AMap.LngLat(Lng * 180 / Math.PI, Lat * 180 / Math.PI)
}

/**
 * 跳转政策详情
 * @param
 */
export function toPolicyDetail (policyId: number | string) {
  if (!policyId && Number(policyId) !== 0) return
  window.open(`${ App.qinqing }#/policy/redirectPage?policyId=${ policyId }`)
}

/**
 * 跳转空间详情
 * @param id 空间id
 * @returns
 */
export function toRoomDetail (id: number | string) {
  if (!id) return
  router.push({
    path: '/park/room',
    query: { id }
  })
}

/**
 * 跳转园区详情
 * @param parkId
 * @returns
 */
export function toParkDetail (parkId: number | string) {
  if (!parkId) return
  router.push({
    path: '/park/detail',
    query: { parkId }
  })
}

// 剩余申报期
export function parseRestTime (data: PolicyItem, nowTime: number) {
  function calcTimeText (data: PolicyItem) {
    let value = '-'
    const { reportEndTime: red } = data

    const diff = red - nowTime
    const days = dayjs.duration(diff).asDays()
    switch (true) {
      case diff <= 0:
        break
      case days <= 1:
        // value = dayjs.duration(diff).format('HH小时mm分ss秒')
        value = dayjs.duration(diff).format('HH小时')
        value = value.substring(0, 1) === '0' ? value.substring(1, value.length) : value
        break
      case days <= 30:
        // value = dayjs.duration(diff).format('DD天HH小时mm分ss秒')
        value = dayjs.duration(diff).format('DD天HH小时')
        const vs = value.split('天')
        vs[0] = vs[0].startsWith('0') ? vs[0].substring(1, vs[0].length) : vs[0]
        vs[1] = vs[1].startsWith('0') ? vs[1].substring(1, vs[1].length) : vs[1]
        value = vs.join('天')
        break
      case days >= 30:
        value = '大于30天'
        break
      default:
        break
    }
    return value
  }

  let value = ''
  const { cashSwitch, chooseType8Id } = data
  // 兑现开关 1：开启 2：关闭
  const cs = Number(cashSwitch)
  const ct8 = Number(chooseType8Id)

  switch (true) {
    case cs !== 1:
      value = '暂未开始'
      break
    case ct8 === 89:
      value = '长期'
      break
    case ct8 === 90:
      value = calcTimeText(data)
      break
    case ct8 === 91:
      value = '-'
      break
    default:
      value = '-'
      break
  }
  return value
}

// 询问登录
export async function enquireLogin (opts: LDOpenParam): Promise<ResponseMode> {
  return new Promise((resolve, reject) => {
    const { isLogin, userType } = store.getters
    const { userType: ut } = opts || {}
    if (isLogin && (!ut || ut === userType)) resolve({ success: true })
    else {
      PubSub.publish(loginDialog.OPEN, {
        ...opts,
        resolve,
        reject
      })
    }
  })
}

// 应用统一跳转
export function openApp (item: AppsRo = {} as AppsRo) {
  const { jumpUrl } = item
  if (!jumpUrl) return ElNotification({
    type: 'info',
    title: '温馨提示',
    message: '应用即将上架，敬请期待',
    duration: 4000
  })
  window.open(jumpUrl)
}

// 跳转到资讯详情
export function openNewsDetail (item: any) {
  const { id, jumpSign, jumpUrl } = item
  if (jumpSign === 1) {
    addNewsNoticeVisitCount({ id })
    window.open(jumpUrl)
  } else {
    window.open(`${ App.basePrefix }/web-company-pc-v2/index.html#/zwyw/qqnews-detail?id=${ id }`)
  }
}

// 跳转到资讯详情
export function openActivityDetail (id: number | string) {
  if (!id) return
  const { href } = router.resolve({
    path: '/micro-activity',
    query: {
      activity: encodeURIComponent('/#/find-activity/detail?id=' + id)
    }
  })
  window.open(href)
}

// meta 文件地址
export function metaFilePath (name: string, aFid?: number) {
  const fid = aFid || Config.project.meta.folders[0]
  if (!fid || !name) return
  return `${ ApiProxy.node.main }/file/download/${ fid }/${ name }`
}

export function transOptions (value: any, options: any[] = [], config?: any) {
  const byKey = config?.byKey || 'id'
  const getKey = config?.getKey || 'name'
  const separator = config?.separator
  if (Array.isArray(value)) {
    const getKeyArr: string[] = []
    map(value, v => {
      const f = find(options, item => {
        return `${ item[byKey] }` === `${ v }`
      })
      if (f) getKeyArr.push(f[getKey])
    })
    return separator ? getKeyArr.join(separator) : getKeyArr
  } else {
    if (separator) {
      const getKeyArr: string[] = []
      map(value.split(separator), v => {
        const f = find(options, item => {
          return `${ item[byKey] }` === `${ v }`
        })
        if (f) getKeyArr.push(f[getKey])
      })
      return getKeyArr.join(separator)
    } else {
      const f = find(options, item => {
        return `${ item[byKey] }` === `${ value }`
      })
      return f?.[getKey]
    }
  }
}

export function imgPath (name: string) {
  return new URL('@static/images/', import.meta.url) + '/' + name
}

// 政策时效
export function parse8Id (data: any) {
  let value = ''
  const { chooseType8Id, reportBeginTime, reportEndTime, reportText } = data
  const ct8 = Number(chooseType8Id)
  switch (true) {
    case ct8 === 89:
      value = '长期有效'
      break
    case ct8 === 90:
      value = `${ parseTime(reportBeginTime, '{y}/{m}/{d}') }~${ parseTime(reportEndTime, '{y}/{m}/{d}') }`
      break
    case ct8 === 91:
      value = reportText
      break
    default:
      break
  }
  return value
}

function string2obj (str: any) {
  let res
  try {
    res = JSON.parse(str)
  } catch (error) {
    // json字符串解析错误时，尝试解析非常规字符串如：'{a:1}'
    try {
      const obj = {}
      res = eval('obj =' + str)
    } catch (err) {
      res = {}
    }
  }
  return res
}

// 解析图
export function cutImgUrl (params: string) {
  try {
    const fileDataObj = string2obj(params)
    const fileObj = fileDataObj[0]
    const filePath = fileObj?.fileUrl || fileObj?.url
    return `${ filePath.indexOf('http') > -1 ? '' : window.location.origin }${ filePath }`
  } catch (error) {
    return 'static/images/app/no-data.png'
  }
}

const location = useSessionStorage('location', '')
const isGetLocation = useSessionStorage('isGetLocation', false)

interface Loca {
  isGetLocation: boolean
  lat: number | string
  lng: number | string
}

export function getPosition (): Promise<Loca> {
  if (isGetLocation.value && !location.value) {
    return new Promise((resolve, reject) => {
      ElNotification({
        type: 'info',
        title: '温馨提示',
        message: '正在获取当前位置，请稍候',
        duration: 4000
      })
      resolve({
        isGetLocation: true,
        lng: '',
        lat: ''
      })
    })
  } else if (location.value) {
    return new Promise((resolve, reject) => {
      isGetLocation.value = false
      resolve({
        isGetLocation: false,
        lng: location.value.split(',')?.[1],
        lat: location.value.split(',')?.[0]
      })
    })
  } else {
    isGetLocation.value = true
    return new Promise((resolve, reject) => {
      const geolocation = new BMapGL.Geolocation()
      geolocation.getCurrentPosition(function (r: any) {
        if (geolocation.getStatus() == 0) {
          const lng = r.point.lng
          const lat = r.point.lat
          location.value = `${ lat },${ lng }`
          isGetLocation.value = false
          resolve({
            isGetLocation: false,
            lng,
            lat
          })
        } else {
          ElNotification({
            type: 'info',
            title: '温馨提示',
            message: '因网络问题，获取当前位置失败，默认定位至滨江区政府',
            duration: 4000
          })
          isGetLocation.value = false
          location.value = '120.21202,30.208316'
          resolve({
            isGetLocation: false,
            lng: 120.21202,
            lat: 30.208316
          })
        }
      })
    })
  }
}
