const { VITE_PROJECT_BASE, VITE_DOMAIN } = import.meta.env

const { VITE_ENV } = import.meta.env

export const isProd = VITE_ENV === 'prod'

export const apiPrefix = isProd ? '/yuhangplatform' : ''

export const BaseConfig = {
  portalBaseUrl: `/${ String(VITE_PROJECT_BASE) }`,
  domain: window.origin
}

// api代理
export const ApiProxy = {
  java: {
    // 主应用
    main: apiPrefix + '/yhqq2_home_company_api',
    qinqing: apiPrefix + '/yh_qinqing_company_api',
    summer: apiPrefix + '/yh_summer_training_personal_api',
    resource: apiPrefix + '/resource-recommend-api',
    fund: apiPrefix + '/com_yhtd_000001',
    activity: apiPrefix + '/activity-company-api',
    activityPerson: apiPrefix + '/activity-person-api'
  },

  node: {
    main: apiPrefix + '/node-szzt'
  }
}

export const App = {
  domain: VITE_DOMAIN,
  basePrefix: apiPrefix,
  suqiu: import.meta.env.VITE_SUQIU_WEB || location.origin,
  qinqing: import.meta.env.VITE_QINQING_WEB || location.origin
}

// 当前项目相关
export default {
  project: {
    id: 298,
    name: '余省心',
    cacheView: true,
    meta: {
      folders: [2100]
    }
  },
  component: {
    prefix: 'el-'
  },
  dictData: {
    FieldUIMapper: {
      e6: 'text',
      e7: 'input',
      e8: 'select',
      e9: 'cascader',
      e10: 'checkbox-group',
      e11: 'radio-group',
      e12: 'switch',
      e13: 'datePicker',
      e14: 'rate',
      e15: 'slider',
      e16: 'tag',
      e17: 'image',
      e18: 'colorPicker',
      e20: 'slot',
      e24: 'input-number',
      e99: 'slot'
    }
  }
}
