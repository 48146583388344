<template>
  <section class="app-menu">
    <el-menu
      :default-active="defaultActive"
      v-bind="uProps"
    >
      <template
        v-for="(menu, index) in uMenus"
        :key="index"
      >
        <template v-if="menu.hidden !== 0">
          <!-- 嵌套 -->
          <menu-sub
            v-if="menu.parentId !== '0' && menu.children && menu.children.length > 0"
            :index="menu.routerPath"
            :menu="menu"
            :emit="emit"
            @clickMenu="onMenu"
          />

          <el-menu-item
            v-else
            :key="index"
            :index="menu.routerPath"
            @click="onMenu(menu)"
          >
            <span>{{ menu.name }}</span>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </section>
</template>
<script lang="ts" setup>
import { open } from '@/utils'
import { MenuMode } from '@/typings/data'
import { computed, PropType } from 'vue'
import { useRoute } from 'vue-router'
import MenuSub from './menu.sub.vue'
import useApp from '@/hooks/state/use-app'
import { get, find } from 'lodash-es'

const props = defineProps({
  menus: { type: Array as PropType<MenuMode[]>, default: () => [] },
  props: { type: Object, default: () => ({}) },
  emit: { type: Boolean, default: false }
})

const emits = defineEmits(['clickMenu'])

// hooks
const route = useRoute()
const { sideMenus: menus } = useApp()

// state
const defaultActive = computed<string>(() => {
  const parentId = get(route, 'meta.data.parentId')
  return parentId === '0'
    ? route.path
    : get(find(uMenus.value, { sid: Number(parentId) }), 'routerPath')
})
const uProps = computed<any>(() => Object.assign({}, props.props))
const uMenus = computed<MenuMode[]>(() =>
  props.menus.length ? props.menus : menus.value
)

// fns
function onMenu (menu: MenuMode) {
  if (props.emit) return emits('clickMenu', menu)
  open(menu)
}
</script>
<style lang="scss" scoped>
.app-menu {
  width: 100%;
  height: 100%;
  background: unset;

  :deep {
    ul.el-menu {
      border: none;
      background: unset;
      --el-menu-horizontal-height: 48px;

      li.el-menu-item {
        flex: 1;
        border-bottom: 4px solid transparent;
        font-weight: 400;
        font-size: 18px;
        color: white;

        &:hover {
          background: rgba($color: $primary-color, $alpha: 0.12);
        }

        &.is-active {
          font-weight: bold;
          background: unset;
          border-color: white;
          color: white !important;
        }
      }
    }
  }
}
</style>
